/**
 * Edit existing client.
 */
import { apiDelete, apiGet, apiPost, apiPut } from '@/util/api';

export default {
  name: 'ClientEdit',
  data() {
    return {
      loading: true,
      nameSaved: '',
      name: '',
      nameState: null,
      nameLoading: false,
      isActive: true,
      isActiveState: true,
      isActiveLoading: false,
      countries: [],
      clientCountries: [],
      countryLoading: false,
      ageRestriction: 'NONE',
      ageRestrictionOptions: [
        { text: this.$t('client_age_restriction_none'), value: 'NONE' },
        { text: this.$t('client_age_restriction_lda'), value: 'LDA' },
        { text: this.$t('client_age_restriction_u21'), value: 'U21' },
      ],
      ageRestrictionState: true,
      ageRestrictionLoading: false,
    };
  },
  async created() {
    // Indicate that the user has been created successfully, if relevant.
    if (this.$route.params.created) {
      this.$bvToast.toast(this.$t('new_client_user_create_success'), {
        title: this.$t('common_success_title'),
        variant: 'success',
      });
    }

    // Get the data required for the page.
    await Promise.all([this.getClient(), this.getCountries()]);

    // Collate countries and clientCountries
    this.clientCountries.forEach(clientCountry => {
      const country = this.countries.find(c => clientCountry.id === c.id);
      if (country) {
        country.access = true;
      }
    });

    // Ok, good to go ...
    this.loading = false;
  },
  methods: {
    /**
     * Check the validity of the name input.
     */
    checkInputValid() {
      this.nameState = this.$refs['name'].checkValidity();
    },

    /**
     * Update the specified property on the client.
     * @param property
     */
    async save(property) {
      if (!this[`${property}State`]) {
        return;
      }

      this[`${property}Loading`] = true;
      const data = {};
      data[property] = this[property];

      await apiPut(`/client/${this.$route.params.clientId}`, data)
        .then(() => {
          this.$bvToast.toast(this.$t('edit_client_success'), {
            title: this.$t('common_success_title'),
            variant: 'success',
          });

          // Update the display name if name was changed.
          if (property === 'name') {
            this.nameSaved = this.name;
          }
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('edit_client_error'), {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        })
        .finally(() => {
          this[`${property}Loading`] = false;
        });
    },

    /**
     * Get the specified client.
     */
    async getClient() {
      await apiGet(`/client/${this.$route.params.clientId}`)
        .then(client => {
          this.client = client;
          this.nameSaved = client.name;
          this.name = client.name;
          this.isActive = client.isActive;
          this.ageRestriction = client.ageRestriction;
          this.clientCountries = client.countries;
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('client_error_get_client'), {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        });
    },

    /**
     * Get the full list of country tags.
     */
    async getCountries() {
      await apiGet('/tag?category=COUNTRY')
        .then(countries => {
          this.countries = countries.sort((c1, c2) => {
            return c1.name === c2.name ? 0 : c1.name < c2.name ? -1 : 1;
          });
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('client_error_get_countries'), {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        });
    },

    /**
     * Assign access to all countries for this client.
     * @param country
     */
    async setAllCountryAccess() {
      this.countryLoading = true;

      await apiPost(`/client/${this.$route.params.clientId}/allCountries`)
        .then(() => {
          // Set access flag on each country
          this.countries.forEach(c => {
            c.access = true;
          });

          this.$bvToast.toast(this.$t('edit_client_add_all_country_success'), {
            title: this.$t('common_success_title'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('edit_client_add_all_country_error'), {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        });

      this.countryLoading = false;
    },

    /**
     * Set whether the client has access to a particular country.
     * Note that because we use @change() event handler to trigger this, country.access will be the opposite of
     * what we expect it to be. Because Vue is slightly odd like that.
     */
    async setCountryAccess(country) {
      this.countryLoading = true;

      if (country.access) {
        // Remove the country from the client's access list
        await apiDelete(
          `/client/${this.$route.params.clientId}/country/${country.id}`,
        )
          .then(() => {
            this.$bvToast.toast(
              this.$t('edit_client_remove_country_success', {
                countryName: country.name,
              }),
              {
                title: this.$t('common_success_title'),
                variant: 'success',
              },
            );
          })
          .catch(() => {
            this.$bvToast.toast(this.$t('edit_client_remove_country_error'), {
              title: this.$t('common_error_title'),
              variant: 'danger',
            });
          });
      } else {
        // Add the country to the client's access list
        await apiPost(
          `/client/${this.$route.params.clientId}/country/${country.id}`,
        )
          .then(() => {
            this.$bvToast.toast(
              this.$t('edit_client_add_country_success', {
                countryName: country.name,
              }),
              {
                title: this.$t('common_success_title'),
                variant: 'success',
              },
            );
          })
          .catch(() => {
            this.$bvToast.toast(this.$t('edit_client_add_country_error'), {
              title: this.$t('common_error_title'),
              variant: 'danger',
            });
          });
      }
      this.countryLoading = false;
    },
  },
};
